/**
 * ----------------------------------------
 * Variables
 * ----------------------------------------
 */

$roboto: 'Roboto';
$open-san: 'Open Sans';

/**
 * ----------------------------------------
 * Exports
 * ----------------------------------------
 */

:export {
  roboto: $roboto;
  open-san: $open-san;
}
